import { LazyLoadImage } from "react-lazy-load-image-component";
import s from "../Image.module.scss";

export default function ({ alt, src }) {
  return (
    <div className={s.large}>
      <LazyLoadImage
        className={s.img}
        src={src}
        alt={alt}
        effect="blur"
        placeholderSrc={src}
      />
    </div>
  );
}
