import LargeImage from "../../components/images/large/LargeImage";
import Live from "../../components/live/Live";
import s from "./Contact.module.scss";
import listStyle from "../lives/Lives.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { addContact } from "../../apis/contact";
import Social from "../../components/social/Social";
import { useState } from "react";
import logo from "../../assets/images/logos/Festirock-106.jpg";
import LivesProvider from "../../services/provider/lives/livesProvider";

export default function Contact() {
  const [isSend, setIsSend] = useState(false);

  const validationSchema = yup.object({
    name: yup
      .string()
      .required("Ce champs doit être saisi")
      .min(2, "Au moins 2 caractères"),
    email: yup
      .string()
      .required("Ce champs doit être saisi")
      .email("Email non valide"),
    phone: yup
      .number()
      .required("Ce champs doit être saisi")
      .typeError("Numéro de téléphone invalide")
      .min(10, "Numéro non valide"),
    subject: yup
      .string()
      .required("Ce champs doit être saisi")
      .min(2, "Au moins 2 caractères"),
    description: yup
      .string()
      .required("Ce champs doit être saisi")
      .min(2, "Au moins 2 caractères"),
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    description: "",
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    clearErrors,
    reset,
  } = useForm({
    initialValues,
    resolver: yupResolver(validationSchema),
  });

  const submit = handleSubmit(async (v) => {
    try {
      clearErrors();
      await addContact(v);
      setIsSend(!isSend);
      reset();
      alert("Votre demande est envoyée !");
    } catch (error) {
      clearErrors();
      console.error(error);
    }
  });

  return (
    <>
      <section className={`${s.contact}`}>
        <h1 className="d-flex jcc">Contact</h1>
        <div className={s.container}>
          <div className={s.headingContact}>
            <div className={`${s.blocContact} d-flex aic`}>
              <h2>Email :</h2>
              <p>contact@thinveilband.com</p>
            </div>
            <div className={`${s.blocContact} d-flex aic`}>
              <h2>Phone :</h2>
              <p>06.28.71.02.51</p>
            </div>
            <div className={`${s.social} desktop d-flex aic`}>
              <h2 className="d-flex f-column aic">Réseaux sociaux</h2>
              <Social className={`${s.follow} d-flex jcse`} />
            </div>
          </div>
          <ul>
            {errors?.phone && (
              <li className={s.liError}>{errors.phone.message}</li>
            )}
          </ul>
          <form onSubmit={submit} className="d-flex f-column aic">
            <input
              placeholder={errors?.name ? errors.name.message : "NOM"}
              className={`${errors?.name && s.error} ${isSend && s.send}`}
              type="text"
              name="name"
              {...register("name")}
              disabled={isSend}
            />
            <input
              placeholder={errors?.email ? errors.email.message : "EMAIL"}
              className={`${errors?.email && s.error} ${isSend && s.send}`}
              type="email"
              name="email"
              {...register("email")}
              disabled={isSend}
            />
            <input
              placeholder={errors?.phone ? errors.phone.message : "PHONE"}
              className={`${errors?.phone && s.error} ${isSend && s.send}`}
              type="tel"
              name="phone"
              {...register("phone")}
              disabled={isSend}
            />
            <input
              placeholder={errors?.subject ? errors.subject.message : "SUJET"}
              className={`${errors?.subject && s.error} ${isSend && s.send}`}
              type="text"
              name="subject"
              {...register("subject")}
              disabled={isSend}
            />
            <textarea
              placeholder={
                errors?.description
                  ? errors.description.message
                  : "Décrivez votre demande"
              }
              className={`${errors?.description && s.error} ${
                isSend && s.send
              }`}
              name="description"
              {...register("description")}
              disabled={isSend}
            />
            {isSend ? (
              <button disabled className={s.send}>
                Valider
              </button>
            ) : (
              <button disabled={isSubmitting}>Valider</button>
            )}
          </form>
        </div>
      </section>
      <section className={`${s.insta}`}>
        <div className="mobile px30">
          <h3 className="d-flex f-column aic">Réseaux sociaux</h3>
          <Social className={`${s.follow} d-flex jcse`} />
        </div>
        <div className="d-flex jcc">
          <LargeImage src={logo} />
        </div>
      </section>
      <section className={`${listStyle.list}`}>
        <h3 className="d-flex jcc m20 p20">Prochainement</h3>
        <LivesProvider>
          <Live />
        </LivesProvider>
      </section>
    </>
  );
}
