import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../services/firebase/config";

const youtubeCollectionRef = collection(db, "youtube");
const youtubeCollectionOrderby = query(youtubeCollectionRef, orderBy("order"));

export async function readYoutube() {
  try {
    const data = await getDocs(youtubeCollectionOrderby);
    const filteredData = data.docs.map((doc) => doc.data());
    return filteredData;
  } catch (error) {
    console.error(error);
  }
}
