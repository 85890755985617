import "react-responsive-carousel/lib/styles/carousel.min.css";
import BioProvider from "../../services/provider/bio/bioProvider";
import Listen from "./components/Listen/Listen";
import Bio from "./components/Listen/components/Bio/Bio";
import s from "./Homepage.module.scss";
import Banner from "./components/banner/banner";
import About from "./components/about/About";

export default function Homepage() {
  return (
    <>
      <h1 className={s.title}>HOMEPAGE</h1>
      <Banner />
      <About />
      <Listen />
      <BioProvider>
        <Bio />
      </BioProvider>
    </>
  );
}
