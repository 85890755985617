import { useContext, useState } from "react";
import OneLive from "./OneLives";
import LargeButton from "../buttons/secondary/largeButton/LargeButton";
import s from "../../pages/lives/Lives.module.scss";
import { Context } from "../../services/context/Context";

export default function Live() {
  const { lives } = useContext(Context);
  const [screenWidth, setScrenWidth] = useState(window.screen.width);

  return (
    <div style={{ overflow: "auto" }} className={`${s.listLives}`}>
      {lives.length > 0 ? (
        lives.map((a, i) => <OneLive live={a} key={i} />)
      ) : (
        <div className={`${s.noLive} d-flex aic jcsb`}>
          {screenWidth >= 1024 ? (
            <h2>Il n'y a plus de date actuellement</h2>
          ) : (
            <h2>Plus de date</h2>
          )}
          <LargeButton text={"Contactez nous pour fixer une date"} />
        </div>
      )}
    </div>
  );
}
