import { Suspense } from "react";
import styles from "./app.module.scss";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import { Outlet, ScrollRestoration } from "react-router-dom";
import BioProvider from "./services/provider/bio/bioProvider";

function App() {
  return (
    <div className={`${styles.appContainer}`}>
      <BioProvider>
        <Header />
      </BioProvider>
      <main className={styles.marginTop}>
        <Suspense>
          <BioProvider>
            <Outlet />
          </BioProvider>
        </Suspense>
      </main>
      <BioProvider>
        <Footer />
      </BioProvider>
      <ScrollRestoration />
    </div>
  );
}

export default App;
