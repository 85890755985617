import s from "./banner.module.scss";
import img1 from "../../../../assets/images/banner/Festirock-67.webp";
import img2 from "../../../../assets/images/banner/Capture-d_écran-2024-04-26-163723.webp";
import img3 from "../../../../assets/images/banner/Festirock-63.webp";
import img4 from "../../../../assets/images/banner/Festirock-123.webp";
import img5 from "../../../../assets/images/banner/Festirock-57.webp";
import logo from "../../../../assets/images/logos/THIN-VEIL-LOGO-1-REVISION-Modifié.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, NavLink } from "react-router-dom";
import { useContext } from "react";
import { BioContext } from "../../../../services/context/BioContext";
import { useState } from "react";

export default function Banner() {
  const { bio } = useContext(BioContext);
  const [id1, setId1] = useState(false);
  const [id2, setId2] = useState(false);
  const [id3, setId3] = useState(false);
  const [id4, setId4] = useState(false);
  const [id5, setId5] = useState(false);

  const handleClick = () => {
    const scrollToY =
      document.getElementById("YOUTUBE").getBoundingClientRect().top +
      window.scrollY;
    window.scrollTo({ top: scrollToY, behavior: "smooth" });
  };

  setTimeout(() => {
    setId1(true);
  }, 500);

  setTimeout(() => {
    setId2(true);
  }, 750);

  setTimeout(() => {
    setId3(true);
  }, 1000);

  setTimeout(() => {
    setId4(true);
  }, 1250);

  setTimeout(() => {
    setId5(true);
  }, 1500);

  return (
    <section className={`${s.banner}`}>
      <div className={s.float}>
        <LazyLoadImage src={logo} alt="logo thinveil horizontal" />
      </div>
      <div
        id="1"
        className={`${s.frame} ${s.top}`}
        style={{ opacity: id1 ? 1 : 0 }}
      >
        <div className={s.name}>
          <h2>JESS</h2>
        </div>
        <LazyLoadImage src={img4} alt="" />
        <NavLink to={"/lives"} title="thinveilband/lives">
          <button className={s.btn}>LIVES</button>
        </NavLink>
      </div>
      <div
        id="2"
        className={`${s.frame} ${s.bottom}`}
        style={{ opacity: id2 ? 1 : 0 }}
      >
        <div className={s.name}>
          <h2>STAN</h2>
        </div>
        <LazyLoadImage src={img2} alt="" />
        <button className={s.btn} onClick={() => handleClick()}>
          VIDEOS
        </button>
      </div>
      <div
        id="3"
        className={`${s.frame} ${s.top}`}
        style={{ opacity: id3 ? 1 : 0 }}
      >
        <div className={s.name}>
          <h2>YANN</h2>
        </div>
        <LazyLoadImage src={img1} alt="" />
        <NavLink to={"/contact"} title="thinveilband/contact">
          <button className={s.btn}>CONTACT</button>
        </NavLink>
      </div>
      <div
        id="4"
        className={`${s.frame} ${s.bottom}`}
        style={{ opacity: id2 ? 1 : 0 }}
      >
        <div className={s.name}>
          <h2>FLO</h2>
        </div>
        <LazyLoadImage src={img5} alt="" />
        <NavLink
          to={`/biographie/${bio[Math.floor(Math.random() * bio.length)].name}`}
          title="thinveilband/biographies"
        >
          <button className={s.btn}>BIOS</button>
        </NavLink>
      </div>
      <div
        id="5"
        className={`${s.frame} ${s.top}`}
        style={{ opacity: id1 ? 1 : 0 }}
      >
        <div className={s.name}>
          <h2>CEZ</h2>
        </div>
        <LazyLoadImage src={img3} alt="" />
        <ul className={s.social}>
          <Link
            className={s.aRS}
            target="_blank"
            to={"https://www.facebook.com/thinveilband/"}
            title="facebook"
          >
            <button className={s.btnRS}>
              <i className="fa-brands fa-facebook-f"></i>
            </button>
          </Link>
          <Link
            className={s.aRS}
            target="_blank"
            to={
              "https://www.instagram.com/thinveilband/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA%3D%3D"
            }
            title="instagram"
          >
            <button className={s.btnRS}>
              <i className="fa-brands fa-instagram"></i>
            </button>
          </Link>
          <Link
            className={s.aRS}
            target="_blank"
            to={"https://twitter.com/ThinVeilBand"}
            title="twitter"
          >
            <button className={s.btnRS}>
              <i className="fa-brands fa-x-twitter"></i>
            </button>
          </Link>
          <Link
            className={s.aRS}
            target="_blank"
            to={"https://www.youtube.com/channel/UCyC448aLaLleW8dnMTt1sWQ"}
            title="youtube"
          >
            <button className={s.btnRS}>
              <i className="fa-brands fa-youtube"></i>
            </button>
          </Link>
          <Link
            className={s.aRS}
            target="_blank"
            to={"https://soundcloud.com/thinveilband"}
            title="soundcloud"
          >
            <button className={s.btnRS}>
              <i className="fa-brands fa-soundcloud"></i>
            </button>
          </Link>
        </ul>
      </div>
    </section>
  );
}
