import { Carousel } from "react-responsive-carousel";
import YouTube, { YouTubeProps } from "react-youtube";
import style from "../../Listen.module.scss";

export default function Carouselyoutube({ youtube }) {
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: "200",
  };

  return (
    <>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        showArrows={true}
        showStatus={false}
        className={style.ytCrsl}
      >
        {youtube &&
          youtube.map((a, i) => (
            <YouTube
              key={i}
              videoId={a.link}
              opts={opts}
              onReady={onPlayerReady}
              className={style.frame}
            />
          ))}
      </Carousel>
    </>
  );
}
