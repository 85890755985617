import s from "./about.module.scss";
import logo from "../../../../assets/images/logos/THIN VEIL LOGO 2 REVISION Modifié Alt Full.png";

export default function About() {
  return (
    <section className={s.about}>
      <div className="d-flex f-column jcc">
        <div className={s.titleBloc}>
          <h1 className={s.title}>THIN VEIL</h1>
          <h2>The french modern hard rock !</h2>
        </div>
        <p>
          Thin Veil c'est 5 gars qui ne se connaissaient ni d'Eve ni d'Adam
          originaires de Béthune, Rennes et Lille, mais qui partagent le goût du
          rock, de la création et de l'éclate. Leur objectif est simple, se
          donner a fond sur scène et prendre plaisir avec le public.
        </p>
      </div>
      <div className={s.logo}>
        <img src={logo} alt="logo thin veil" />
      </div>
    </section>
  );
}
