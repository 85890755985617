import Live from "../../components/live/Live";
import s from "./Lives.module.scss";
import LivesProvider from "../../services/provider/lives/livesProvider";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo/logo.jpg";
import Fresque from "../../components/images/fresque/Fresque";
import { useEffect, useState } from "react";
import { readFresque } from "../../apis/fresque";

export default function Lives() {
  const [listImages, setListImages] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    async function loadImages() {
      const files = await readFresque();
      setListImages(files);
    }
    setTimeout(() => {
      setLoader(!loader);
    }, 200);
    loadImages();
  }, []);

  return (
    <section>
      <h1 className="d-flex jcc">LIVES</h1>
      <div className={`${s.list}`}>
        <LivesProvider>
          <Live />
        </LivesProvider>
      </div>
      <div className={`${s.follow} d-flex f-column aic`}>
        <NavLink
          to={"/contact"}
          className={"m20"}
          title="thinveilband.com/contact"
        >
          <h2>Contactez nous pour fixer une date !</h2>
        </NavLink>
        {loader && <Fresque listImages={listImages} />}
      </div>
    </section>
  );
}
