import { Link } from "react-router-dom";
import s from "./social.module.scss";

export default function Social({ className }) {
  return (
    <nav className={`${className} ${s.nav}`}>
      <Link
        target="_blank"
        to={"https://www.facebook.com/thinveilband/"}
        title="facebook"
      >
        <i className="fa-brands fa-facebook-f"></i>
      </Link>
      <Link
        target="_blank"
        to={
          "https://www.instagram.com/thinveilband/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA%3D%3D"
        }
        title="instagram"
      >
        <i className="fa-brands fa-instagram"></i>
      </Link>
      <Link
        target="_blank"
        to={"https://twitter.com/ThinVeilBand"}
        title="twitter"
      >
        <i className="fa-brands fa-x-twitter"></i>
      </Link>
      <Link
        target="_blank"
        to={"https://www.youtube.com/channel/UCyC448aLaLleW8dnMTt1sWQ"}
        title="youtube"
      >
        <i className="fa-brands fa-youtube"></i>
      </Link>
      <Link
        target="_blank"
        to={"https://soundcloud.com/thinveilband"}
        title="soundcloud"
      >
        <i className="fa-brands fa-soundcloud"></i>
      </Link>
      <Link
        to={
          "https://linkaband.com/thin-veil?utm_source=badge&utm_campaign=132161"
        }
        target="_blank"
        title="linkaband"
      >
        <img
          src="https://linkaband.com/assets/images/validation/reservation-noir.png"
          alt="Thin-veil"
        />
      </Link>
    </nav>
  );
}
