import { LazyLoadImage } from "react-lazy-load-image-component";
import s from "./fresque.module.scss";
import { useState } from "react";

export default function Fresque({ listImages }) {
  const [photo, setPhoto] = useState(null);

  return (
    <section className={s.fresque}>
      {photo && (
        <div className={s.position}>
          <LazyLoadImage
            className={s.bigPhoto}
            src={photo}
            alt="big photo chacal !"
            onClick={() => {
              setPhoto(null);
            }}
          />
        </div>
      )}
      {listImages[0].list
        .map((a) => a)
        .map((b, i) => (
          <div key={i} className={s.frame}>
            <LazyLoadImage
              effect="blur"
              delayTime="1000"
              className={s.img}
              src={b.web}
              alt="img TV"
              height="380px"
              onClick={() => {
                setPhoto(b.original);
              }}
            />
          </div>
        ))}
    </section>
  );
}
