import {
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase/config";

const livesCollectionRef = collection(db, "lives");
const today = Timestamp.fromDate(new Date());
const livesCollectionRefOrder = query(
  livesCollectionRef,
  where("date", ">=", today)
);

export async function readLives() {
  try {
    const data = await getDocs(livesCollectionRefOrder);
    const filteredData = data.docs.map((doc) => doc.data());
    return filteredData;
  } catch (error) {
    console.error(error);
  }
}
