import { createHashRouter } from "react-router-dom";
import App from "../App";
import Homepage from "../pages/homepage/Homepage";
import Biography from "../pages/biography/Biography";
import Lives from "../pages/lives/Lives";
import Contact from "../pages/contact/Contact";
import NotFound from "../pages/404/NotFound";
import LivesLoader from "../services/loader/lives/livesLoader";
import BioLoader from "../services/loader/bio/bioLoader";

export const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    loader: BioLoader,
    children: [
      {
        path: "/",
        element: <Homepage />,
        loader: BioLoader,
      },
      {
        path: "/biographie/:pseudo",
        element: <Biography />,
        loader: BioLoader,
      },
      {
        path: "/lives",
        element: <Lives />,
        loader: LivesLoader,
      },
      {
        path: "/contact",
        element: <Contact />,
        loader: LivesLoader,
      },
    ],
  },
]);
