import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { readYoutube } from "../../../../apis/youtube";
import Comingsoon from "./components/ComingSoon/Comingsoon";
import Carouselyoutube from "./components/Carousel/Carouselyoutube";
import style from "./Listen.module.scss";
import Soundcloud from "./components/Soundcloud/Soundcloud";
import { LazyLoadComponent } from "react-lazy-load-image-component";

export default function Listen() {
  const [currentYoutube, setCurrentYoutube] = useState([]);

  useEffect(() => {
    async function loadingYoutube() {
      const allYoutube = await readYoutube();
      setCurrentYoutube(allYoutube);
    }
    loadingYoutube();
  }, []);

  return (
    <section
      id="YOUTUBE"
      className={`${style.youtube} d-flex f-column aic YOUTUBE`}
    >
      <LazyLoadComponent>
        <h2 className="m20">Listen</h2>
        {currentYoutube.length > 0 ? (
          <div className={`${style.crsl} d-flex f-column aic`}>
            <Carouselyoutube youtube={currentYoutube} />
            <div>
              <Link
                target="_blank"
                to={"https://www.youtube.com/channel/UCyC448aLaLleW8dnMTt1sWQ"}
              >
                Chaîne Youtube
              </Link>
            </div>
          </div>
        ) : (
          <Comingsoon />
        )}
        <Soundcloud />
      </LazyLoadComponent>
    </section>
  );
}
