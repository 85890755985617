import s from "./Footer.module.scss";
import logov from "../../assets/images/logos/THIN_VEIL_LOGO_2_REVISION_Modifie_Alt.jpg";
import { Link, NavLink } from "react-router-dom";
import { useContext } from "react";
import Social from "../social/Social";
import { BioContext } from "../../services/context/BioContext";

export default function Footer() {
  const { bio } = useContext(BioContext);

  return (
    <footer>
      <div className={`${s.footerBloc}`}>
        <div className={`${s.navBloc}`}>
          <div className={`${s.follow} d-flex aic`}>
            <h3 className="rubik">Suivez nous sur</h3>
            <Social className={"d-flex"} />
          </div>
          <div className={`${s.divider} mobile`}></div>
          <div className={`${s.nav}`}>
            <div>
              <h4>TITRE</h4>
              <p>
                Thin Veil, "the french modern hard rock" originaires de Béthune,
                Rennes et Lille, mais qui partagent le goût du rock, de la
                création et de l'éclate. Leur objectif est simple, se donner a
                fond sur scène et prendre plaisir avec le public.
                <br />
                <br />
                Aux influences de Gun'n'Roses, Mötley Crüe, Motörhead, Aerosmith
                et bien d'autres légendes du rock !
              </p>
            </div>
            <nav>
              <h3>Pages</h3>
              <ul className="d-flex f-column">
                <li>
                  <NavLink to={"/"} title="thinveilband/homepage">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/biographie/${
                      bio[Math.floor(Math.random() * bio.length)].name
                    }`}
                    title="thinveilband/biographies"
                  >
                    Biographie
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/lives"} title="thinveilband/lives">
                    Lives
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/contact"} title="thinveilband/contact">
                    Contact
                  </NavLink>
                </li>
              </ul>
            </nav>
            <div className={`${s.divider} mobile`}></div>
          </div>
        </div>
        <div className={`${s.divider} mobile`}></div>
        <div className={s.logo}>
          <img src={logov} alt="logo verticale" />
        </div>
      </div>
      <div className={` ${s.divider}`}></div>
      <nav className={s.copyright}>
        <p className="strait">
          Copyright <i className="fa-regular fa-copyright"></i> Thin Veil |
          Designed and Powered by{" "}
          <Link
            target="_blank"
            to={"https://florentin-crespel.fr/"}
            title="Florentin Crespel - Développeur web"
          >
            Florentin Crespel
          </Link>
        </p>
        <nav className={`${s.follow} d-flex jcse`}>
          <div className="d-flex jcsa">
            <Link
              to={"https://www.facebook.com/CrespelDev"}
              target="_blank"
              title="facebook"
            >
              <i className="fa-brands fa-facebook-f"></i>
            </Link>
            <Link
              to={"https://www.instagram.com/florentin.crespel/"}
              target="_blank"
              title="instagram"
            >
              <i className="fa-brands fa-instagram"></i>
            </Link>
            <Link
              to={"https://www.linkedin.com/in/florentin-crespel-37a59a188/"}
              target="_blank"
              title="linkedin"
            >
              <i className="fa-brands fa-linkedin"></i>
            </Link>
          </div>
        </nav>
      </nav>
    </footer>
  );
}
