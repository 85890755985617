import { NavLink } from "react-router-dom";
import s from "../../Button.module.scss";

export default function LargeButton({ text }) {
  return (
    <NavLink to={"/contact"}>
      <button className={s.lSecondary}>{text}</button>
    </NavLink>
  );
}
