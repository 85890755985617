import { collection, getDocs } from "firebase/firestore";
import { db } from "../services/firebase/config";

const bioCollectionRef = collection(db, "bio");

export async function readBio() {
  try {
    const data = await getDocs(bioCollectionRef);
    const filteredData = data.docs.map((doc) => doc.data());
    return filteredData;
  } catch (error) {
    console.error(error);
  }
}
