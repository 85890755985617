import { Link } from "react-router-dom";
import s from "./Live.module.scss";

export default function OneLive({ live }) {
  const test = 2 * 60 * 60 * 1000;
  const formatDate = live.date.toDate();
  const timeUpdated = formatDate + test;

  const formatter = new Intl.DateTimeFormat("fr-FR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
  });

  const formatterDate = formatter.format(formatDate);

  return (
    <div className={`${s.live}`}>
      <h2>
        {live.location} <span className={s.adress}>{live.adress}</span> :{" "}
        {live.price}€
      </h2>
      <div className={`${s.secondary}`}>
        <p>{formatterDate}</p>
        <Link target="_blank" to={live.link} title={`${live.link}`}>
          <i className="fa-solid fa-arrow-up-right-from-square"></i>
        </Link>
      </div>
    </div>
  );
}
