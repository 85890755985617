import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../services/firebase/config";

export async function addContact(v) {
  const contactCollectionRef = collection(db, "contact");
  try {
    await addDoc(contactCollectionRef, {
      name: v.name,
      email: v.email,
      phone: v.phone,
      subject: v.subject,
      description: v.description,
      date: new Date(),
    });
    newClient(v);
  } catch (error) {
    console.error(error);
  }
}

async function newClient(v) {
  console.log(v);
  try {
    const clientCollectionRef = collection(db, "client");
    const newClientData = query(
      clientCollectionRef,
      where("name", "==", v.name)
    );
    const data = await getDocs(newClientData);
    const searchClient = data.docs.map((doc) => doc.data());
    if (searchClient.length === 0) {
      await addDoc(clientCollectionRef, {
        name: v.name,
        email: v.email,
        phone: v.phone,
      });
    }
  } catch (error) {
    console.error(error);
  }
}
