import { Link } from "react-router-dom";

export default function Soundcloud() {
  return (
    <div>
      <Link target="_blank" to={"https://soundcloud.com/thinveilband"}>
        Ecoutez nous aussi sur Soundcloud
      </Link>
    </div>
  );
}
