import { useContext } from "react";
import LargeImage from "../../../components/images/large/LargeImage";
import s from "../Biography.module.scss";
import { useParams } from "react-router-dom";
import { BioContext } from "../../../services/context/BioContext";

export default function OneBio() {
  const { bio } = useContext(BioContext);
  const pseudo = useParams().pseudo;

  let numeroLigne = 0;

  for (let i = 0; i < bio.length; i++) {
    if (bio[i].name === pseudo) {
      numeroLigne = i;
      break;
    }
  }

  const oneBio = bio.slice(numeroLigne, numeroLigne + 1);

  return (
    <>
      <section className={`${s.desc} d-flex f-column aic`}>
        <h2 className="px30">{oneBio[0].name.toUpperCase()}</h2>
        <div className={`${s.mainBloc} d-flex f-column aic`}>
          <div className={s.img}>
            <LargeImage
              src={`${oneBio[0].image1}`}
              alt={`image 1 de ${oneBio[0].name}`}
            />
          </div>
          <div className={`${s.bloc} d-flex f-column`}>
            <h2>Qui est-il ?</h2>
            <p>{oneBio[0].resume}</p>
          </div>
        </div>
      </section>
      <section className={`${s.influences} d-flex f-column aic`}>
        <div className={s.img}>
          <LargeImage
            src={oneBio[0].image2}
            alt={`image 2 de ${oneBio[0].name}`}
          />
        </div>
        <div className={`${s.bloc} d-flex f-column`}>
          <h2>Ses influences</h2>
          <p>{oneBio[0].influences}</p>
        </div>
      </section>
      <section className={`${s.matos} d-flex f-column aic`}>
        <div className={s.img}>
          <LargeImage
            src={oneBio[0].image3}
            alt={`image 3 de ${oneBio[0].name}`}
          />
        </div>
        <div className={`${s.bloc} d-flex f-column`}>
          <h2>Son matos</h2>
          {oneBio[0].instru.length > 0 && (
            <>
              <h3>Gratte :</h3>
              <ul className="strait">
                {oneBio[0].instru.map((a, i) => (
                  <li className={`${s.boardList} strait`} key={i}>
                    {a}
                  </li>
                ))}
              </ul>
            </>
          )}
          {oneBio[0].amp.length > 0 && (
            <>
              <h3>Ampli :</h3>
              <ul className="strait">
                {oneBio[0].amp &&
                  oneBio[0].amp.map((a, i) => (
                    <li className={`${s.boardList} strait`} key={i}>
                      {a}
                    </li>
                  ))}
              </ul>
            </>
          )}
          {oneBio[0].effect.length > 0 && (
            <>
              <h3>Effects :</h3>
              <ul className="strait">
                {oneBio[0].effect.map((a, i) => (
                  <li className={`${s.boardList} strait`} key={i}>
                    {a}
                  </li>
                ))}
              </ul>
            </>
          )}
          {oneBio[0].micro.length > 0 && (
            <>
              <h3>Micro :</h3>
              <ul className="strait">
                {oneBio[0].micro.map((a, i) => (
                  <li className={`${s.boardList} strait`} key={i}>
                    {a}
                  </li>
                ))}
              </ul>
            </>
          )}
          {oneBio[0].batterie.length > 0 && (
            <>
              <h3>Batterie :</h3>
              <ul className="strait">
                {oneBio[0].batterie.map((a, i) => (
                  <li className={`${s.boardList} strait`} key={i}>
                    {a}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </section>
    </>
  );
}
