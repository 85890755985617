import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { readBio } from "../../../apis/bio";
import { BioContext } from "../../context/BioContext";

export default function BioProvider({ children }) {
  const initialBio = useLoaderData();
  const [bio, setBio] = useState(initialBio);

  function readBios(credentials) {
    readBio(credentials).then((res) => {
      setBio(res);
    });
  }
  return (
    <BioContext.Provider value={{ bio, readBios }}>
      {children}
    </BioContext.Provider>
  );
}
