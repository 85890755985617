import Cardbio from "../../components/card/bio/Cardbio";
import BioProvider from "../../services/provider/bio/bioProvider";
import s from "./Biography.module.scss";
import OneBio from "./OneBio/OneBio";

export default function Biography() {
  return (
    <>
      <section className="d-flex f-column aic">
        <h1>BIOGRAPHIE</h1>
        <div className={`${s.bio} d-flex jcse`}>
          <BioProvider>
            <Cardbio />
          </BioProvider>
        </div>
      </section>
      <BioProvider>
        <OneBio />
      </BioProvider>
      <section className={s.bio}>
        <h2 className="d-flex jcc">voir aussi</h2>
        <div className={`${s.list} d-flex jcsb`}>
          <BioProvider>
            <Cardbio />
          </BioProvider>
        </div>
      </section>
    </>
  );
}
