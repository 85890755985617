import Cardbio from "../../../../../../components/card/bio/Cardbio";

export default function Bio() {
  return (
    <section>
      <h2 className="p20 d-flex jcc">Biographies</h2>
      <div className="d-flex">
        <Cardbio />
      </div>
    </section>
  );
}
