import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { readLives } from "../../../apis/lives";
import { Context } from "../../context/Context";

export default function LivesProvider({ children }) {
  const initialLives = useLoaderData();
  const [lives, setLives] = useState(initialLives);

  function readLive(credentials) {
    readLives(credentials).then((res) => {
      setLives(res);
    });
  }

  return (
    <Context.Provider value={{ lives, readLive }}>{children}</Context.Provider>
  );
}
