import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logos/THIN-VEIL-LOGO-1-REVISION-Modifié.webp";
import s from "./Header.module.scss";
import { useContext, useEffect, useState } from "react";
import Social from "../social/Social";
import { BioContext } from "../../services/context/BioContext";

export default function Header() {
  const [burger, setBurger] = useState(false);
  const { bio } = useContext(BioContext);

  function toggleBurger() {
    setBurger(!burger);
  }

  useEffect(() => {
    if (burger === true) {
      const position = window.scrollY;

      document.body.style.overflow = "hidden";

      return () => {
        document.body.style.overflow = "auto";
        window.scrollTo(0, position);
      };
    }
  }, [burger]);

  return (
    <header className="d-flex aic jcsb">
      <div className={`${s.header} d-flex aic jcsb`}>
        <div className={`${s.before} mobile`}></div>
        <div className={`${s.img} d-flex aic jcc`}>
          <NavLink to={"/"} title="thinveilband.com">
            <img src={logo} alt="logo_THINVEIL" className={`${s.logo}`} />
          </NavLink>
        </div>
        <nav className={`${s.burger} mobile`}>
          <span onClick={toggleBurger} className={s.cross}>
            <i className="fa-solid fa-bars"></i>
          </span>
          <ul
            style={{ display: !burger ? "none" : "flex" }}
            className={`${s.menuBurger}`}
          >
            <span onClick={toggleBurger} className={s.cross}>
              <i className="fa-solid fa-xmark"></i>
            </span>
            <NavLink onClick={toggleBurger} to={"/"} className={"strait"}>
              Home
            </NavLink>
            <NavLink
              onClick={toggleBurger}
              to={`/biographie/${
                bio[Math.floor(Math.random() * bio.length)].name
              }`}
              className={"strait"}
            >
              Biographie
            </NavLink>
            <NavLink onClick={toggleBurger} to={"/lives"} className={"strait"}>
              Lives
            </NavLink>
            <NavLink
              onClick={toggleBurger}
              to={"/contact"}
              className={"strait"}
            >
              Contact
            </NavLink>
          </ul>
        </nav>
        <div className={`${s.nav} desktop d-flex aic jcsb flex-fill`}>
          <nav className={"desktop m10"}>
            <NavLink to={"/"} className={"strait"}>
              Home
            </NavLink>
            <NavLink
              to={`/biographie/${
                bio[Math.floor(Math.random() * bio.length)].name
              }`}
              className={"strait"}
            >
              Biographie
            </NavLink>
            <NavLink to={"/lives"} className={"strait"}>
              Lives
            </NavLink>
            <NavLink to={"/contact"} className={"strait"}>
              Contact
            </NavLink>
          </nav>
          <Social className={"desktop"} />
        </div>
      </div>
    </header>
  );
}
