// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBM1nImOfRwHkPZq9I1y-tEFj9_9Rj8d8Y",
  authDomain: "thinveil-4e2f0.firebaseapp.com",
  projectId: "thinveil-4e2f0",
  storageBucket: "thinveil-4e2f0.appspot.com",
  messagingSenderId: "5917504344",
  appId: "1:5917504344:web:9a67775c60193f20b26b2d",
  measurementId: "G-J69CMKNPHQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
// const analytics = getAnalytics(app);
export const p = getStorage(app);

export const db = getFirestore(app);
