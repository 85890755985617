import { collection, getDoc, getDocs } from "firebase/firestore";
import { db } from "../services/firebase/config";

export async function readFresque() {
  const fresqueColelction = collection(db, "fresque");

  try {
    const data = await getDocs(fresqueColelction);
    const filteredData = data.docs.map((doc) => doc.data());
    return filteredData;
  } catch (error) {
    console.error(error);
  }
}

export async function readOneImage() {}
