import s from "./Comingsoon.module.scss";

export default function Comingsoon() {
  return (
    <div className={`${s.tv} d-flex aic jcc`}>
      <div className={`${s.hp} d-flex aic jcc`}>
        <div className={`${s.noise}`}>
          <div className={`${s.tvScreen}`}>
            <div className={`${s.borderScreen}`}>
              <h2 className="d-flex aic jcc">Coming soon</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
