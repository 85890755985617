import { NavLink } from "react-router-dom";
import s from "./NotFound.module.scss";

export default function NotFound() {
  return (
    <div className={s.notfound}>
      <div className={`${s.noise} d-flex aic jcc`}>
        <p className={s.title} data-content="EURREUR 404">
          ERREUR 404
        </p>
        <NavLink to={"/"} title="thinveilband/">
          BACK TO HOMEPAGE
        </NavLink>
      </div>
    </div>
  );
}
