import { NavLink, useParams } from "react-router-dom";
import s from "../Cardbio.module.scss";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function OneCard({ bio }) {
  const name = bio.name.toUpperCase();

  const test = useParams().pseudo;
  const test2 = test && test.toUpperCase();

  const matched = test2 === name;

  return (
    <NavLink
      className={`${s.card} ${matched && s.actived}`}
      to={`/biographie/${bio.name}`}
    >
      <div className={`${s.img} ${matched && s.active}`}>
        <LazyLoadImage
          src={bio.image1}
          alt={`image de ${name}`}
          effect="blur"
        />
      </div>
      <h2 className="d-flex aic jcc">{name}</h2>
    </NavLink>
  );
}
